import { get as _get } from 'lodash';
import { datadogRum } from '@datadog/browser-rum';
import { getConfigValue } from 'e1p-portals-util-js';
import appConfig from 'app-config';

export default class DatadogService {
    /**
     * Initialize Datadog RUM
     */
    static initializeDatadog() {
        if(datadogRum) {
            const appId = getConfigValue('datadog.applicationId');
            const token = getConfigValue('datadog.clientToken');
            const environment = _get(appConfig, 'env.AMFAM_ENV', 'local');

            if (appId && token && environment) {
                datadogRum.init({
                    applicationId: appId,
                    clientToken: token,
                    site: 'datadoghq.com',
                    service: 'gw-producer-engage',
                    env: environment,
                    sessionSampleRate: 100,
                    sessionReplaySampleRate: 100,
                    trackUserInteractions: true,
                    trackResources: true,
                    trackLongTasks: true,
                    defaultPrivacyLevel: 'mask-user-input',
                });
            }
        }
    }    
}